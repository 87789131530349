import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const HSRGuidesGameModesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Game modes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_gamemodes.png"
            alt="Game modes"
          />
        </div>
        <div className="page-details">
          <h1>Game modes</h1>
          <h2>List of available game modes in Honkai: Star Rail.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Game modes" />
        <p>
          In addition to exploring the overworld and doing missions, Honkai:
          Star Rail also offers a number of other game modes for the player to
          enjoy as well.
        </p>
        <SectionHeader title="Resource/Boss Stages" />
        <p>
          Throughout the overworld in Honkai: Star Rail, you will encounter a
          number of dungeon type entrances that you can enter to farm resources
          and materials to progress your account. However, Trailblaze Power must
          be expended in order to obtain the rewards for completing these
          stages.
        </p>
        <p>The different stages are:</p>
        <h5>Calyx</h5>
        <StaticImage
          src="../../../images/starrail/generic/guide_calyx.jpg"
          alt="Guide"
        />
        <p>
          These are your basic resource stages and provide one of the following
          material depending on which Calyx stage you are farming:
        </p>
        <ul>
          <li>Calyx: Bud of Memories - Character EXP material</li>
          <li>Calyx: Bud of Aether - Light Cone EXP material</li>
          <li>Calyx: Bud of Grit - Credits (in game money)</li>
        </ul>
        <p>
          There are also 7 different Calyx stages, each corresponding to one of
          the 7 Paths. These Calyx cycle daily and provide Character Trace
          upgrade materials and Light Cone Ascension materials.
        </p>
        <p>It costs 10 Trailblaze Power to do one Calyx stage.</p>
        <p>
          You can queue up to 6 Calyx runs at a time. Essentially, this allows
          you to repeat the same Calyx stage without having to exit and
          re-enter. Each run will cost you 10 Trailblaze Power (60 Trailblaze
          Power total if you queue up 6 runs).
        </p>
        <h5>Stagnant Shadow</h5>
        <ul>
          <li>
            These stages reward Character ascension materials. Each stage drops
            a different ascension material, so make sure to check your Character
            page to see which one the Character that you are ascending needs.
          </li>
          <li>It costs 30 Trailblaze Power to do one Stagnant Shadow stage.</li>
        </ul>
        <h5>Cavern of Corrosion</h5>
        <ul>
          <li>These stages reward Relics.</li>
          <li>
            It costs 40 Trailblaze Power to do one Caven of Corrosion stage.
          </li>
        </ul>
        <h5>Echo of War</h5>
        <ul>
          <li>
            These are your weekly boss stages and reward Relics, Tracks of
            Destiny (Character skill level upgrade material), and Light Cones.
          </li>
          <li>
            You can only do 3 Echo of War runs every week. However, you can do
            the same Echo of War stage multiple times.
          </li>
          <li>It costs 30 Trailblaze Power to do one Echo of War stage.</li>
        </ul>
        <p>Here's a video showing the mode in action:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="32LaKfdGve8" />
          </Col>
        </Row>
        <SectionHeader title="Forgotten Hall" />
        <p>
          Forgotten Hall is a game mode where the objective is to defeat a group
          of enemies in as few turns as possible. After completing a Forgotten
          Hall stage you will be awarded between 0 to 3 stars for that stage
          depending on how many turns it took you to defeat the enemies in that
          stage. The more stars that you are awarded, the better the rewards you
          receive.
        </p>
        <p>
          It is a more challenging game mode with the final stages being
          considered as end game content.
        </p>
        <p>
          Forgotten Hall is divided in two parts: Memory and Memory of Chaos.
        </p>
        <ul>
          <li>
            Memory consists of 15 stages and provides a one time reward of
            Stellar Jades upon completion.
          </li>
          <ul>
            <li>
              The later stages of Memory require the use of two teams of up to 4
              Characters.
            </li>
          </ul>
          <li>
            Memory of Chaos consists of 10 stages and reward Stellar Jades upon
            completion
          </li>
          <ul>
            <li>
              Memory of Chaos stages are more challenging than Memory stages.
            </li>
            <li>
              Memory of Chaos stages reset periodically, allowing the player to
              complete them again for additional Stellar Jades.
            </li>
          </ul>
        </ul>
        <p>Here's a video showing the mode in action:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="ZvvGSK01_uc" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Simulated Universe" />
        <StaticImage
          src="../../../images/starrail/generic/guide_sim.jpg"
          alt="Guide"
        />
        <p>
          Simulated Universe is a roguelike game mode where the player will
          select a team of four Characters to venture inside a simulated world
          to collect a random set of buffs that they can use to enhance their
          team and fight the enemies inside. The player will progress through a
          set of semi-randomized combat and non-combat stages with the goal of
          defeating the boss in the final stage.
        </p>
        <p>
          This game mode does not require the player to spend any Trailblaze
          Power to play it and there is no limit to how many times the player
          can start a run in the Simulated Universe. The player can however
          optionally spend Trailblaze Power to obtain Planetary Ornaments
          (Character equipment) while inside certain stages of the Simulated
          Universe.
        </p>
        <p>
          Players can also earn rewards such as Stellar Jades and Star Rail
          Passes on a weekly basis via this game mode.
        </p>
        <p>Learn more about this mode here:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Simulated Universe"
            link="/star-rail/guides/simulated-universe"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_sim.png"
                alt="Simulated Universe"
              />
            }
          />
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesGameModesPage;

export const Head: React.FC = () => (
  <Seo
    title="Game modes | Honkai: Star Rail | Prydwen Institute"
    description="List of available game modes in Honkai: Star Rail."
  />
);
